import React from 'react';

import DealList from 'modules/DealList';
import Map from 'modules/Map';

import './OysterAssassins.scss';

const OysterAssassins = () => (
  <div className="oa">
    <header className="oa-header">Oyster Assassins</header>
    <div className="oa-content">
      <div className="oa-nav">
        <DealList />
      </div>
      <div className="oa-map">
        <Map />
      </div>

      <div className="clear" />
    </div>
  </div>
);

export default OysterAssassins;
