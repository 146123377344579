import defaultState from './defaultState';
import { types } from './actions';

export default function DealList(state = defaultState, action) {
  switch (action.type) {
    case types.SET_SELECTED_DAY: {
      return Object.assign({}, state, {
        selectedDay: action.selectedDay,
      });
    }

    default: {
      return state;
    }
  }
}
