import { DAYS, DEAL_TYPES } from 'common/constants';

export default {
  selectedDay: DAYS.MONDAY,

  deals: {
    [DAYS.MONDAY]: [
      {
        restaurant: {
          name: 'Stamp\'s Lane',
        },
        type: DEAL_TYPES.BUCK_A_SHUCK,
      },
    ],
  },
};
