import React from 'react';
import PropTypes from 'prop-types';

import './DealList.scss';

const DealList = (props) => {
  const {
    deals,
    selectedDay,
    setSelectedDay,
  } = props;

  console.log(deals);

  const updateDay = () => {
    setSelectedDay(`${selectedDay} ${new Date().getTime()}`);
  };

  const currentDate = new Date();
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const locale = navigator.language || 'en-US';


  const currentDayString = currentDate.toLocaleDateString(locale, options);


  return (
    <div className="deal_list">
      <div className="current_day">
        <div>Today is</div>
        <div>{currentDayString}</div>
      </div>
      <div tabIndex="0" onClick={updateDay} onKeyPress={updateDay} role="button">{selectedDay}</div>
    </div>
  );
};

DealList.propTypes = {
  deals: PropTypes.shape({}),
  selectedDay: PropTypes.string.isRequired,
  setSelectedDay: PropTypes.func.isRequired,
};

DealList.defaultProps = {
  deals: {},
};

export default DealList;
