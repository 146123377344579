import React from 'react';
import { combineReducers, compose, createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import thunk from 'redux-thunk';

// Reducers
import DealList from 'modules/DealList/reducer';

import OysterAssassins from './OysterAssassins';


const appReducer = combineReducers({
  DealList,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line

const store = createStore(
  appReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

const app = () => (
  <Provider store={store}>
    <Router>
      <OysterAssassins />
    </Router>
  </Provider>
);

export default app;
