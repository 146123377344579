export const types = {
  SET_SELECTED_DAY: 'SET_SELECTED_DAY',
};

export function setSelectedDay(selectedDay) {
  return {
    type: types.SET_SELECTED_DAY,
    selectedDay,
  };
}
