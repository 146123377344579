import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  setSelectedDay,
} from './reducer/actions';

import DealList from './DealList';

const mapStateToProps = state => ({
  selectedDay: state.DealList.selectedDay,
  deals: state.DealList.deals,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setSelectedDay,
  }, dispatch)
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DealList);
